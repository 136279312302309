import React, { useContext } from 'react';
import { Divider, Typography, Box } from '@mui/material';
import ActiveRequests from './ActiveRequests';
import RequestsTable from './RequestsTable';
import { UserContext } from '../../context/UserContext';

const Requests = () => {
  const { authenticatedUser, isError, isLoading } = useContext(UserContext);
  if(isLoading) {
    return <p> Loading... </p>;
  }
  if(isError) {
    return <p> Error fetching user </p>;
  }
  return (
    <>
      <ActiveRequests userId={authenticatedUser.id}/>
      <Divider />
      <Typography variant="h6" sx={{ m: '1rem' }} gutterBottom>
        History
      </Typography>
      <Box p="0" display="flex" alignItems="center" justifyContent="center">
        <RequestsTable userId={authenticatedUser.id} />
      </Box>
    </>
  );
  
};

export default Requests;
