import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Modal,
  Stack,
  IconButton,
  Input,
  Container,
  CircularProgress,
  Button,
  Pagination,
} from '@mui/material';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import AlertMessage from '../../components/AlertMessage';
import {
  DBAccessCredentials,
  DbApiClient,
  InlineResponse20014,
  Pagination as ApiPagination,
  DBAccessRequest,
} from '../../api';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ConnectionDetailsModal = (props: { id: string; requestApproved: boolean }) => {
  const { id, requestApproved } = props;
  const [credentials, setCredentials] = useState<DBAccessCredentials | undefined>(undefined);
  const [showDatabasePassword, setShowDatabasePassword] = useState<boolean>(false);
  const [showWindowsPassword, setShowWindowsPassword] = useState<boolean>(false);
  const [showUsername, setShowUsername] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = React.useState('');

  const handleShowDatabasePassword = () => {
    setShowDatabasePassword(!showDatabasePassword);
  };
  const handleShowWindowsPassword = () => {
    setShowWindowsPassword(!showWindowsPassword);
  };
  const handleShowUsername = () => {
    setShowUsername(!showUsername);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    if (!credentials) {
      DbApiClient.getDatabaseConnectionDetails(+id)
        .then((res) => {
          setCredentials(res.data);
        })
        .catch(() => console.error('Failed to get DB user credentials'));
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalInfo('');
    setShowDatabasePassword(false);
    setShowWindowsPassword(false);
    setShowUsername(false);
  };
  return (
    <Box>
      <Button size="small" onClick={() => handleOpenModal()} disabled={!requestApproved}>
        Get connection details
      </Button>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          {modalInfo && <AlertMessage type="info"> {modalInfo} </AlertMessage>}
          {!modalInfo && (
            <AlertMessage type="info">
              <Typography>
                To use this credentials you need to do it from a Bastion host.{' '}
                <Link href="https://docs.eposnow.io/development/platform/database/productiondb/">
                  More information.
                </Link>
              </Typography>
            </AlertMessage>
          )}
          {!credentials && (
            <Container
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pt: 1,
                minHeight: '128px',
              }}
            >
              <Box>
                <CircularProgress />
              </Box>
            </Container>
          )}
          {credentials && (
            <Stack sx={{ pt: 1 }}>
              <Stack direction="row" spacing={2}>
                <Box sx={{ width: 100 }}>
                  <Typography variant="h6" component="h2">
                    Username
                  </Typography>
                </Box>
                <Box sx={{ width: 188 }}>
                  <Input
                    type={showUsername ? 'text' : 'password'}
                    value={credentials.username}
                    readOnly
                    fullWidth
                  />
                </Box>
                <Box>
                  <IconButton
                    aria-label="Copy Username"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        credentials.username ? credentials.username : ''
                      );
                      setModalInfo('Username copied');
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton aria-label="Show username" onClick={() => handleShowUsername()}>
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box sx={{ width: 100 }}>
                  <Typography variant="h6" component="h2">
                    Win pass
                  </Typography>
                </Box>
                <Box sx={{ width: 188 }}>
                  <Input
                    type={showWindowsPassword ? 'text' : 'password'}
                    value={credentials.windowsPassword}
                    readOnly
                    fullWidth
                  />
                </Box>
                <Box>
                  <IconButton
                    aria-label="Copy bastion host password"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        credentials.windowsPassword ? credentials.windowsPassword : ''
                      );
                      setModalInfo('Bastion host password copied');
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Show bastion host password"
                    onClick={() => handleShowWindowsPassword()}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box sx={{ width: 100 }}>
                  <Typography variant="h6" component="h2">
                    DB pass
                  </Typography>
                </Box>
                <Box sx={{ width: 188 }}>
                  <Input
                    type={showDatabasePassword ? 'text' : 'password'}
                    value={credentials.databasePassword}
                    readOnly
                    fullWidth
                  />
                </Box>
                <Box>
                  <IconButton
                    aria-label="Copy database password"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        credentials.databasePassword ? credentials.databasePassword : ''
                      );
                      setModalInfo('Database password copied');
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Show database password"
                    onClick={() => handleShowDatabasePassword()}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              </Stack>
            </Stack>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

const ActiveRequests = (props: {userId?: number}) => {
  const { userId } = props;
  const [page, setPage] = useState<number>(1); // Pagination starts numbering from 1
  const pageSize = 5;
  const [pagination, setPagination] = useState<ApiPagination>();
  const { data, status } = useQuery<InlineResponse20014>({
    queryKey: ['getRequests', page],
    queryFn: () =>
      DbApiClient.getRequests(
        page,
        pageSize,
        userId,
        ['Approved', 'Requested'],
        undefined,
        undefined,
        true
      ),
    enabled: true,
    retry: 3,
    retryDelay: (attempt) => attempt * 1000, // linear backoff
    refetchInterval: 300000, // 5 min
    refetchIntervalInBackground: true,
  });

  function getPagesTotal(): number {
    if (!pagination) return 0;
    return pagination.total_pages;
  }

  const handleOnChangePage = (_event: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
  };

  useEffect(() => {
    setPagination(data?.pagination as ApiPagination);
  }, [data]);

  if (status === 'success' && data?.data.requests && data.data.requests.length) {
    return (
      <Box>
        <>
          <Typography component="h2" variant="h6" sx={{ m: '1rem' }} gutterBottom>
            My Active Requests
          </Typography>
          <List dense sx={{ width: '50%', maxWidth: '900px', bgcolor: 'background.paper' }}>
            {data.data.requests.map((request) => (
              <ListItem
                key={request.ID}
                secondaryAction={
                  <ConnectionDetailsModal
                    id={`${request.ID}`}
                    requestApproved={request.Status === DBAccessRequest.StatusEnum.Approved}
                  />
                }
                sx={{ mb: '1rem' }}
              >
                {request.Approved ? (
                  <ListItemText>
                    Request for{' '}
                    <Box component="span" fontWeight="fontWeightBold">{`${request.Target}`}</Box> is
                    valid until {`${request.ValidUntil}`}
                  </ListItemText>
                ) : (
                  <ListItemText>
                    Request for{' '}
                    <Box component="span" fontWeight="fontWeightBold">{`${request.Target}`}</Box> ,
                    during a period of {`${request.ValidFor}`}, is yet to be approved
                  </ListItemText>
                )}
              </ListItem>
            ))}
          </List>
          {pagination && pagination.total_records > pageSize && (
            <Pagination
              size="small"
              sx={{ marginLeft: '1rem', marginBottom: '1rem' }}
              count={getPagesTotal()}
              onChange={handleOnChangePage}
              page={page}
            />
          )}
          <Button
            size="medium"
            startIcon={<EnhancedEncryptionIcon />}
            component={RouterLink}
            to="/requests/create/read_production_database"
            sx={{ marginLeft: '1rem', marginBottom: '1rem' }}
            reloadDocument
          >
            Request access
          </Button>
        </>
      </Box>
    );
  }
  return (
    <Box>
      <Typography variant="h6" sx={{ m: '1rem' }} gutterBottom>
        My Active Requests
      </Typography>
      {status === 'error' && (
        <Typography variant="body1" sx={{ m: '1rem' }} gutterBottom>
          Error fetching data
        </Typography>
      )}
      {status === 'loading' && (
        <Typography variant="body1" sx={{ m: '1rem' }} gutterBottom>
          Fetching data...
        </Typography>
      )}
      {status === 'success' && (
        <>
          <Typography variant="body1" sx={{ m: '2rem' }} gutterBottom>
            There are no active requests
          </Typography>
          <Button
            size="medium"
            startIcon={<EnhancedEncryptionIcon />}
            component={RouterLink}
            to="/requests/create/read_production_database"
            sx={{ marginLeft: '1rem', marginBottom: '1rem' }}
            onClick={() => console.log('ToDo')}
          >
            Request access
          </Button>
        </>
      )}
    </Box>
  );
};

export default ActiveRequests;
